import anime from "animejs";

const mainAssetsUrl = 'https://strapi-dcb6d488.needmorecoffee.it';
const strapiApiEndpoint = `${mainAssetsUrl}/api`;

let strapi = {
    offsetWriterToken: process.env.STRAPI_API_KEY,
    gufWriteEndpoint: `${strapiApiEndpoint}/guf-2022`,

    assetsUrl: mainAssetsUrl,
    apiEndpoint: strapiApiEndpoint,
    gufEndpoint: `${strapiApiEndpoint}/guf-2022?populate[0]=run.runner.socials.platform.icon&populate[1]=run.runner_2.socials.platform.icon&populate[2]=ads&populate[3]=freezeMedia`,
    gameYearEndpoint: `${strapiApiEndpoint}/game-years?pagination[pageSize]=100`,
    donationLeaderboardEndpoint: `${strapiApiEndpoint}/guf-data`,
    gufDonationsEndpoint: `${strapiApiEndpoint}/guf-data`,
    gufLastDonationsEndpoint: `${strapiApiEndpoint}/donations`,
    gufRunEndpoint: `${strapiApiEndpoint}/runs?pagination[pageSize]=1000&populate[0]=bgm`,
    gufRunData: [],
    gufData: [],
    gameYearData: [],
    gufDonationData: [],
    oldDonatorLeaderboard: [],
    incentives: [],
    toCallWhenReady: [],
    toCallWhenUpdated: [],
    toCallWhenDonationsUpdated: [],
    firstInit: true,
    lastDonationId: 0,
    lastDonations: [],

    currentBgmIndex: 0,

    currentBgmScrollIndex: 0,
    maxBgmScrollIndex: 2,
    bgmScrollMaxLeft: 0,
    bgmScrollLeft: 0,
    bgmScrollElements: [],
    bgmCurrentTitle: '',
    scrollBgmInterval: null,

    shouldSendAds: false,
    adSendingTimeout: null,

    scrollBgmLoop: () => {
        strapi.bgmScrollElements = document.querySelectorAll('#media-player .media-title-text');
        strapi.bgmScrollMaxLeft = strapi.bgmScrollElements[strapi.currentBgmScrollIndex].clientWidth;
        strapi.bgmScrollElements.forEach((element, i) => {
            element.style.order = i;
        });
        strapi.scrollBgmInterval = setInterval(function () {
            strapi.bgmScrollElements.forEach((element) => {
                element.style.left = strapi.bgmScrollLeft + 'px';
            });

            strapi.bgmScrollLeft -= 1;
            if (strapi.bgmScrollLeft <= strapi.bgmScrollMaxLeft * -1) {
                strapi.bgmScrollLeft = 0;
                strapi.currentBgmScrollIndex++;
                if (strapi.currentBgmScrollIndex > strapi.maxBgmScrollIndex)
                    strapi.currentBgmScrollIndex = 0;

                strapi.bgmScrollElements.forEach((element) => {
                    let order = parseInt(element.style.order);
                    order--;
                    if (order < 0)
                        order += strapi.bgmScrollElements.length;

                    element.style.order = order;
                });
                strapi.bgmScrollMaxLeft = strapi.bgmScrollElements[strapi.currentBgmScrollIndex].clientWidth;
            }
        }, 30);
    },

    fetchGufInfo: async () => {
        console.log("Fetching GUF info from strapi...");
        const response = await fetch(strapi.gufEndpoint);
        const responseJson = await response.json();
        strapi.gufData = responseJson.data;
        if (strapi.firstInit) {
            strapi.toCallWhenReady.forEach(toCall => { toCall(); });
            strapi.firstInit = false;
        }
        strapi.toCallWhenUpdated.forEach(toCall_1 => { toCall_1(); });
    },

    fetchGameYearInfo: async () => {
        // console.log("Fetching game/year info from strapi...");
        const response = await fetch(strapi.gameYearEndpoint);
        const responseJson = await response.json();
        strapi.gameYearData = responseJson.data;
    },

    fetchGufRunInfo: async () => {
        const response = await fetch(strapi.gufRunEndpoint);
        const responseJson = await response.json();
        strapi.gufRunData = responseJson.data;
    },

    fetchGufDonationInfo: async () => {
        // console.log("Fetching GUF donation info from strapi...");
        const response = await fetch(strapi.gufDonationsEndpoint);
        const responseJson = await response.json();
        strapi.gufDonationData = responseJson.data;

        strapi.toCallWhenDonationsUpdated.forEach(toCall => { toCall(); });
    },

    fetchGufDonationsSinceLast: async () => {
        if (strapi.lastDonationId === 0) {
            const lastDonationIdUrl = `${strapi.gufLastDonationsEndpoint}?fields[0]=id&sort[0]=id:desc&pagination[limit]=1`;
            const responseLastId = await fetch(lastDonationIdUrl);
            const responseLastIdJson = await responseLastId.json();
            strapi.lastDonationId = responseLastIdJson.data[0]?.id ?? 0;
        }
        const lastDonationsUrl = `${strapi.gufLastDonationsEndpoint}?fields[0]=id&fields[1]=amount&filters[id][$gt]=${strapi.lastDonationId}&pagination[pageSize]=1000`;
        const response = await fetch(lastDonationsUrl);
        const responseJson = await response.json();
        strapi.lastDonations = responseJson;

        if (strapi.lastDonations.data.length !== 0)
            strapi.lastDonationId = strapi.lastDonations.data.sort((a, b) => b.id - a.id)[0]?.id ?? 0;

        const lastDonationAmounts = strapi.lastDonations.data.map(d => d.attributes.amount);
        strapi.animateLastDonations(lastDonationAmounts);
    },

    animateLastDonations: (donationAmounts) => {
        donationAmounts.forEach(
            (d, i) => setTimeout(strapi.createAndAnimateDonation, 1000 * i + Math.floor(Math.random() * 200), d)
        );
    },

    createAndAnimateDonation: (donationAmount) => {
        const donationElement = strapi.createDonation(donationAmount);
        strapi.animateDonation(donationElement);
    },

    createDonation: (donationAmount) => {
        const donationFeedbackContainerElement = document.querySelector('#donation-feedback');
        const donationElement = document.createElement('div');
        donationElement.classList.add('donation');

        if (donationAmount >= 10 && donationAmount < 25) {
            donationElement.classList.add('donation-l');
        } else if (donationAmount >= 25 && donationAmount < 100) {
            donationElement.classList.add('donation-xl');
        } else if (donationAmount >= 100) {
            donationElement.classList.add('donation-xxl');
        }

        donationElement.innerHTML = `+${new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(donationAmount)}`;

        donationFeedbackContainerElement.appendChild(donationElement);
        return donationElement;
    },

    animateDonation: (donationElement) => {
        anime({
            targets: donationElement,
            easing: 'easeInOutQuad',
            translateX: function () { return anime.random(0, window.debugDonationMaxXSpawn); },
            translateY: function () { return anime.random(200, 205); },
            scale: 1.2,
            opacity: 0,
            duration: 0,
            complete: () => {
                anime({
                    targets: donationElement,
                    easing: 'easeInOutQuad',
                    opacity: 1,
                    duration: 250,
                    complete: () => {
                        anime({
                            targets: donationElement,
                            easing: 'easeInOutQuad',
                            scale: 1,
                            duration: 200,
                            complete: () => {
                                anime({
                                    targets: donationElement,
                                    easing: 'easeInOutQuad',
                                    translateY: `-=25}`,
                                    opacity: 0,
                                    duration: 1500,
                                    complete: () => {
                                        donationElement.remove();
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    },

    getMarathonId: () => {
        return strapi.gufData?.attributes?.oengusSlug;
    },

    getMarathonDate: () => {
        return strapi.gufData?.attributes?.runDate;
    },

    getYearForRunId: (runId) => {
        return strapi.gameYearData?.filter(i => i.attributes.runId === runId)[0]?.attributes?.year ?? '';
    },

    getTotalDonationOffset: () => {
        return strapi.gufData?.attributes?.donationOffset ?? 0;
    },

    getTotalDonation: () => {
        return strapi.gufDonationData?.attributes?.totalDonations ?? 0;
    },

    getTotalDonationsWithOffset: () => {
        const totalDonation = strapi.getTotalDonation() + strapi.getTotalDonationOffset();
        if (totalDonation <= 0) return 0;

        return totalDonation;
    },

    getDonatorsLeaderboard: () => {
        let donatorLeaderboard = strapi.gufDonationData?.attributes?.topDonors?.map((donation, i) => {
            const oldData = strapi.oldDonatorLeaderboard[i];
            const newData = strapi.getDonatorsLeaderboardUpdateCheckKey(donation);

            let donationFiltered = {
                id: donation.id,
                name: donation.name,
                total: donation.total,
                updated: oldData !== newData,
            };

            strapi.oldDonatorLeaderboard[i] = newData;
            return donationFiltered;
        }) ?? [];


        return donatorLeaderboard;
    },

    getDonationLeaderboard: () => {
        let donationLeaderboard = strapi.gufDonationData?.attributes?.topDonations?.map(donation => {
            let filteredDonations = {
                id: donation.id,
                name: donation.name,
                total: donation.total,
            };

            return filteredDonations;
        }) ?? [];


        return donationLeaderboard;
    },

    getDonatorsLeaderboardUpdateCheckKey: (donationAttributes) => {
        if (donationAttributes === undefined) return '';

        return donationAttributes.id + '-' + donationAttributes.name + '-' + donationAttributes.total;
    },

    getInforollRows: () => {
        return strapi.gufData?.attributes?.infoRoll;
    },

    getFreezeInforoll: () => {
        return strapi.gufData?.attributes?.freezeInforoll ?? false;
    },

    getFreezeInforollMedia: () => {
        return strapi.gufData?.attributes?.freezeMedia?.data ?? [];
    },

    getFrozenInforollText: () => {
        return strapi.gufData?.attributes?.frozenInforollText ?? '';
    },

    getOffsetHours: () => {
        return -strapi.gufData?.attributes?.offsetHours ?? 0;
    },

    getOffsetMinutes: () => {
        return -strapi.gufData?.attributes?.offsetMinutes ?? 0;
    },

    getDonationReason: () => {
        return strapi.gufData?.attributes?.charityText ?? '';
    },

    getCurrentRun: () => {
        return strapi.gufData?.attributes?.run?.data?.attributes ?? [];
    },


    getRunner: (ord) => {
        return oengus.getPlayingNow().submission?.runners.filter(r => r.order === ord - 1)[0] ?? null;
    },

    getRunnerName: (ord) => {
        return strapi.getRunner(ord)?.user?.name ?? '';
    },

    getRunnerSocials: (ord) => {
        return strapi.getRunner(ord)?.user?.socials ?? [];
    },

    getRunnerSocialsData: (ord) => {
        return strapi.getRunnerSocials(ord)
            .filter(social => social.type === 'twitch' || social.type === 'youtube')
            .map(function (social) {
                const handle = social?.handle ?? '';
                const type = social?.type ?? '';
                let icon = '';
                if (type === 'twitch') {
                    icon = '/uploads/icon_twitch_f4e80a88e8.png';
                } else if (type === 'youtube') {
                    icon = '/uploads/icon_youtube_bb32763b9e.png';
                }

                if (icon !== '') {
                    icon = strapi.assetsUrl + icon;
                }

                return { handle: handle, icon: icon };
            }) ?? [];
    },

    getHostName: () => {
        return strapi.gufData?.attributes?.host;
    },

    shouldShowLeaderboard: () => {
        return strapi.gufData?.attributes?.showLeaderboard ?? true;
    },

    getDonationGoalAmount: () => {
        return strapi.gufData?.attributes?.goalAmount ?? 0;
    },

    getDonationGoalFlair: () => {
        return strapi.gufData?.attributes?.goalFlair ?? '';
    },

    getDonationDeploySurprise: () => {
        return strapi.gufData?.attributes?.goalDeploySurprise ?? false;
    },

    getBreakScreenAds: () => {
        return (strapi.gufData?.attributes?.ads?.data ?? []).map(ad => ad.attributes?.url ?? '');
    },

    getCouch: () => {
        return strapi.gufData?.attributes?.run?.data?.attributes?.commenters ?? '';
    },

    getBreakScreenHost: () => {
        return strapi.gufData?.attributes?.host ?? '';
    },

    parseIncentives: () => {
        strapi.incentives = [];
        strapi.gufDonationData?.attributes?.fixedIncentives?.map(rawIncentive => {
            let incentive = [];
            incentive.type = 'fixed';
            incentive.name = rawIncentive.name;
            incentive.total = rawIncentive.total;
            incentive.goal = rawIncentive.goal;
            incentive.gameName = rawIncentive.gameName;
            incentive.category = rawIncentive.category;
            strapi.incentives.push(incentive);
        })

        const optionIncentives = strapi.gufDonationData?.attributes?.optionIncentives;
        let parentIds = strapi.gufDonationData?.attributes?.optionIncentives?.map(i => i.parentId);
        let unique = [];
        parentIds.map(i => unique[i] = i);
        unique = unique.filter(Number);
        unique.map(parentId => {
            let childrenIncentive = optionIncentives.filter(o => o.parentId == parentId);
            let firstIncentive = childrenIncentive[0];
            let incentive = [];
            incentive.type = 'option';
            incentive.name = firstIncentive.parentName;
            incentive.total = firstIncentive.goal;
            incentive.gameName = firstIncentive.gameName;
            incentive.category = firstIncentive.category;
            incentive.options = []
            childrenIncentive.map(childIncentive => {
                if (childIncentive.id == null) return;

                let innerIncentive = [];
                innerIncentive.id = childIncentive.id;
                const regex = /^(.*?) -.*$/ig;
                innerIncentive.name = childIncentive.name.replaceAll(regex, '$1');
                innerIncentive.total = Number(childIncentive.total);
                incentive.options.push(innerIncentive);
            })
            incentive.options.sort((a, b) => b.total - a.total);
            strapi.incentives.push(incentive);
        })
    },

    setOffset: (hours, minutes, date, runId) => {
        const data = {
            data: {
                offsetHours: hours,
                offsetMinutes: minutes,
                runDate: date,
                run: runId,
            }
        };
        const response = fetch(strapi.gufWriteEndpoint, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${strapi.offsetWriterToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((data) => {
            console.log(data);
            window.location.reload();
        });
    },

    sendAds: () => {
        const data = {
            data: {
                sendAds: true,
            }
        };
        const response = fetch(strapi.gufDonationsEndpoint, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${strapi.offsetWriterToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((data) => {
            console.log("Sent ads and got back:", data);
        });
    },

    sendShoutout: () => {
        const data = {
            data: {
                sendShoutout: true,
            }
        };
        const response = fetch(strapi.gufDonationsEndpoint, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${strapi.offsetWriterToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((data) => {
            console.log("Sent shoutout and got back:", data);
        });
    },

    getTotalUnreadDonations: () => {
        return strapi.gufDonationData?.attributes?.totalUnreadDonations ?? 0;
    },

    getNextRunBgms: () => {
        let nextRunOengusId = oengus.getNextRun(0)?.id;
        let nextRun = strapi.gufRunData.filter((run) => run.attributes.runId == nextRunOengusId)[0];
        if (!nextRun.attributes?.bgm?.data)
            return [];

        return nextRun.attributes?.bgm?.data ?? [];
    },

    getNextRunBgm: () => {
        let bgms = [];
        if (strapi.getFreezeInforoll())
            bgms = strapi.getFreezeInforollMedia();
        else
            bgms = strapi.getNextRunBgms();

        if (bgms.length == 0)
            return [];

        if (bgms.length <= strapi.currentBgmIndex)
            strapi.currentBgmIndex = 0;

        return bgms[strapi.currentBgmIndex].attributes ?? [];
    },

    playNextRunBgmWithDelay: () => {
        setTimeout(strapi.playNextRunBgm, 3000);
    },

    setBgmMediaTitles: (nextRunBgm) => {
        const titleArray = [
            nextRunBgm?.caption || '',
            nextRunBgm?.alternativeText || '',
        ];
        strapi.bgmCurrentTitle = titleArray.join(' - ');
        anime({
            targets: '#media-player .media-title-container',
            opacity: 0,
            duration: 2000,
            easing: 'linear',
            complete: () => {
                strapi.bgmScrollLeft = 0;
                clearInterval(strapi.scrollBgmInterval);
                document.querySelectorAll('#media-player .media-title-text')
                    .forEach(element => element.innerText = strapi.bgmCurrentTitle);
                strapi.scrollBgmLoop();
                anime({
                    targets: '#media-player .media-title-container',
                    opacity: 1,
                    duration: 2000,
                    easing: 'linear',
                });
            }
        });
    },

    playNextRunBgm: () => {
        let nextRunBgm = strapi.getNextRunBgm();
        if (!nextRunBgm || nextRunBgm.length == 0)
            return;

        console.log("Next run BGM not empty, playing", nextRunBgm);
        strapi.playBgm(nextRunBgm);

        strapi.currentBgmIndex++;
    },

    playBgm: (bgm) => {
        strapi.setBgmMediaTitles(bgm);
        let sourceSelector = document.querySelector("#media-player audio source");
        const bgmType = bgm.mime;
        const bgmUrl = strapi.assetsUrl + bgm.url;
        sourceSelector.type = bgmType;
        sourceSelector.src = bgmUrl;
        console.log("BGM", bgmType, bgmUrl);
        let audio = document.querySelector("#media-player audio");
        audio.load();
        audio.volume = 0;
        anime({
            targets: audio,
            duration: 500,
            easing: 'linear',
            volume: 0.2,
        });
        audio.play();
    },

    callWhenReady: (fn) => {
        strapi.toCallWhenReady.push(fn);
    },

    callWhenUpdated: (fn) => {
        strapi.toCallWhenUpdated.push(fn);
    },

    callWhenDonationsUpdated: (fn) => {
        strapi.toCallWhenDonationsUpdated.push(fn);
    },
}

export { strapi };
window.strapi = strapi;
window.playBgm = false;
window.cake = false;
strapi.callWhenReady(function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    window.cake = urlParams.get('cake') === 'true' || false;
    if (window.cake)
        console.log("The cake is a lie.");

    strapi.shouldSendAds = urlParams.get('sendAds') === 'true' || false;
    if (strapi.shouldSendAds) {
        strapi.adSendingTimeout = setTimeout(strapi.sendAds, 10000);
        console.log("Sending ads in 10 seconds.");
    }

    const shouldSendShoutout = urlParams.get('sendShoutout') === 'true' || false;
    if (shouldSendShoutout) {
        setTimeout(function () {
            strapi.sendShoutout();
            console.log("Sending shoutout.");
        }, 1000 * 60 * 2);
    }

    const bgm = urlParams.get('bgm') === 'true' || false;
    if (!bgm)
        return;

    window.playBgm = true;

    console.log("BGM enabled, setting up event listeners and playing first BGM...");
    const audio = document.querySelector("#media-player audio");
    audio.addEventListener("ended", strapi.playNextRunBgmWithDelay, false);
    audio.volume = 0.2;
    strapi.playNextRunBgmWithDelay();
});

strapi.callWhenReady(function () {
    strapi.scrollBgmLoop();
});